<template>
  <div id="review">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">

        <router-link :to="`/jackpot/add`">
        <vs-button class="mr-6" type="border"  size="small" icon-pack="feather"  icon="icon-edit" color="success" > เพิ่มรูปภาพ</vs-button>
        </router-link>
     
      </div>
              <vs-table stripe  max-items="20" pagination :data="listReviewData" search>
    <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>รูป</vs-th>
          <vs-th>สถานะแสดงหน้าเว็บ</vs-th>
          <vs-th>วันที่อัพโหลด</vs-th>
          <vs-th></vs-th>

    </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.img_id">
                {{tr.img_id}}
            </vs-td>
            <vs-td  :data="tr.img_url" >
               <!-- {{tr.img_url}} -->
               <vs-button class="mr-6" type="border"  size="small" color="success" @click="showIMG(tr.img_id)"> ดูรูป </vs-button>
            </vs-td>
            <vs-td v-if="tr.img_status == true"  :data="tr.img_status" >
               <p class="text-success">แสดงหน้าเว็บ</p>
            </vs-td>
            <vs-td v-else  :data="tr.img_status" >
               <p class="text-danger">ไม่แสดง</p>
            </vs-td>
            <vs-td :data="tr.img_datetime">
                {{moment(tr.img_datetime).format('YYYY-MM-DD HH:mm:ss')  }}
            </vs-td>
            <vs-td>
            </vs-td>
            <vs-td>
        <vs-button v-if="$store.state.AppActiveUser.permissions.review.action" class="mr-6" type="border"  size="small" icon-pack="feather"  icon="icon-edit" color="danger" @click="popupDeleteIMG(tr.img_id, tr.img_name, tr.img_url)"> ลบรูป</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </div>

    <!-- popup_show_img -->
    <vs-popup classContent="popup-example" title="Username" :active.sync="popupShowIMG">
        <img :src="showIMGurl" :alt="showIMGurl" :srcset="showIMGurl" width="100%" height="100%">
    </vs-popup>

    <!-- popup_delete_img -->
    <vs-popup classContent="popup-example" title="Username" :active.sync="showPopupDeleteIMG">
      <div class="container text-center">
        <vs-button class="mr-6 mb-2" type="border"  size="small" icon-pack="feather"  icon="icon-edit" color="danger" @click="deleteIMG()"> ยืนยันลบรูป</vs-button>
        <vs-button class="mr-6" type="border"  size="small" icon-pack="feather"  icon="icon-edit" color="success" @click="showPopupDeleteIMG=false"> ยกเลิก</vs-button>

      </div>
    </vs-popup>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import log from '../../log'
// import moment from 'moment'
// import store from '../../store/store'

export default {
  name: 'Review',
  components: {
    vSelect,
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      listReviewData: [],
      popupShowIMG: false,
      showPopupDeleteIMG: false,
      showIMGurl: '',
      dataDelImgID: '',
      dataDelImgURL: '',
      dataDelImgName: '',
      resDelete: [],
      urlaws3: process.env.VUE_APP_AWS3_URL,
      nameTag  :  process.env.VUE_APP_TITLE
    }
  },
  async mounted () {
    // this.gridApi = this.gridOptions.api
    // if (window.innerWidth > 768) {
    //   this.gridApi.sizeColumnsToFit()
    // }
    await this.listImgReview()
  },
  methods: {
    async listImgReview () {
      await axios
        .get(
          'jackpot/list'
        )
        .then(response => (this.listReviewData = response.data))
    },
    showIMG (id) {
      
      if (this.nameTag === '1688SAGAME') {
        this.showIMGurl =  `${this.urlaws3}jackpot/${id}.jpg`
      } else {
        this.showIMGurl =  `${this.urlaws3}jackpot/${id}.png`
      }
  
      this.popupShowIMG = true

    },
    popupDeleteIMG (imgID, imgName, imgURL) {
      this.dataDelImgID = imgID
      this.dataDelImgURL = imgURL
      this.dataDelImgName = imgName
      this.showPopupDeleteIMG = true
    },
    async deleteIMG () {
      await axios
        .post('jackpot/delete', {
          img_id: this.dataDelImgID
        })
        .then(response => (this.resDelete = response.data))
      if (this.resDelete.status === true) {
        
        await this.deleteS3(this.resDelete.data)

        await log.agent('', 'Delete_Photo_Review', 0, `ลบรูปรีวิว ID : ${this.resDelete.data}`)
        
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.resDelete.info.title,
          text: this.resDelete.info.info
        })
        await this.listImgReview()
        this.dataDelImgID = ''
        this.dataDelImgURL = ''
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.resDelete.info.title,
          text: this.resDelete.info.info
        })
      }
      this.showPopupDeleteIMG = false
    },
    async deleteS3 (id) {

      const formData = new FormData()
      formData.append('useId', true)
      formData.append('path', 'jackpot')
      formData.append('namePic', id) // appending file

      // sending file to the backend
      await axios
        .post('aws3/upload/delete', formData)
        .then()
        .catch()

    }
  }
}
</script>

<style>

</style>
